import { IUsersides } from '../../interfaces/iuserside';
// Hier definiert man jeweils vier Aktionen für CRUD-Operationen

// Read
export class GetUsersides {
    static readonly type = '[Usersides] Fetch';
}

// sort
export class GetSortUsersides {
    static readonly type = '[UsersidesSort] Fetch/Sort';
}

// Create
export class AddUsersides {
    static readonly type = '[Usersides De] Add';
    constructor(public payload: IUsersides) { }
}

// Update
export class UpdateUsersides {
    static readonly type = '[Usersides De] Update';
    constructor(public payload: IUsersides, public id: number, public i: number) { }
}

// Delete
export class DeleteUsersides {
    static readonly type = '[UsersidesDe] Delete';
    constructor(public id: number) { }
}
