import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IWerbung } from '../../../interfaces/iwerbung';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import werbeData from '../../../data/data.json';


@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],

})
export class DialogComponent implements OnInit {
  public imageData = dataHawle;
  public imageDataKochabo = dataKochabo;
  public imageDataAdmiral = dataAdmiral;
  public imageDataDrei2014 = dataDrei2014;
  public imageDataDrei2012 = dataDrei2012;
  public imageDataEva = dataEva;
  public imageDataGustav3 = dataGustav3;
  public imageDataGustav2 = dataGustav2;
  public imageDataTracks = dataTracks;
  public imageDataNufex = dataNufex;
  public imageDataToniTipp = dataToniTipp;
  public imageDataAsfinag = dataAsfinag;
  public imageDataModemacher = dataModemacher;
  public imageDataSchaerdinger = dataSchaerdinger;
  public imageDataTowntown = dataTowntown;
  public imageDataEristoff = dataEristoff;
  public imageDataDj = dataDj;
  public imageDataSchlank = dataSchlank;
  public imageDataTtc = dataTtc;
  public imageDataMarkant = dataMarkant;
  public imageDataRock = dataRock;
  public imageDataTrattnerzone = dataTrattnerzone;
  public imageDataIon = dataIon;
  public imageDataOrf = dataOrf;
  public imageDataBarbara = dataBarabara;
  public imageDataMystikum = dataMystikum;
  public imageDataSeelenreise = dataSeelenreise;
  public imageDataOffenestellen = dataOffenestellen;
  public imageDataSilentelements = dataSilentelements;
  public imageDataIrocode2011 = dataIrocode2011;
  public imageDataVd = dataVd;

  items: GalleryItem[];
  itemsKochabo: GalleryItem[];
  itemsAdmiral: GalleryItem[];
  itemsDrei2012: GalleryItem[];
  itemsDrei2014: GalleryItem[];
  itemsEva: GalleryItem[];
  itemsGustav3: GalleryItem[];
  itemsGustav2: GalleryItem[];
  itemsTracks: GalleryItem[];
  itemsNufex: GalleryItem[];
  itemsToniTipp: GalleryItem[];
  itemsAsfinag: GalleryItem[];
  itemsModemacher: GalleryItem[];
  itemsSchaerdinger: GalleryItem[];
  itemsTowntown: GalleryItem[];
  itemsDj: GalleryItem[];
  itemsEristoff: GalleryItem[];
  itemsRock: GalleryItem[];
  itemsSchlank: GalleryItem[];
  itemsMarkant: GalleryItem[];
  itemsTtc: GalleryItem[];
  itemsTrattnerzone: GalleryItem[];
  itemsIon: GalleryItem[];
  itemsOrf: GalleryItem[];
  itemsBarabara: GalleryItem[];
  itemsMystikum: GalleryItem[];
  itemsSeelenreise: GalleryItem[];
  itemsOffenestellen: GalleryItem[];
  itemsSilentelements: GalleryItem[];
  itemsIrocode2011: GalleryItem[];
  itemsVd: GalleryItem[];


  public dataList: IWerbung[] = werbeData.employees;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public gallery: Gallery, public lightbox: Lightbox,
              private dialogRef: MatDialogRef<DialogComponent>) { }

  ngOnInit() {
    this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsKochabo = this.imageDataKochabo.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsAdmiral = this.imageDataAdmiral.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsDrei2012 = this.imageDataDrei2012.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsDrei2014 = this.imageDataDrei2014.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsEva = this.imageDataEva.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsGustav3 = this.imageDataGustav3.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsGustav2 = this.imageDataGustav2.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsTracks = this.imageDataTracks.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsNufex = this.imageDataNufex.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsToniTipp = this.imageDataToniTipp.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsAsfinag = this.imageDataAsfinag.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsModemacher = this.imageDataModemacher.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsSchaerdinger = this.imageDataSchaerdinger.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsTowntown = this.imageDataTowntown.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsDj = this.imageDataDj.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsEristoff = this.imageDataEristoff.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsSchlank = this.imageDataSchlank.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsMarkant = this.imageDataMarkant.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsTtc = this.imageDataTtc.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsRock = this.imageDataRock.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsTrattnerzone = this.imageDataTrattnerzone.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsIon = this.imageDataIon.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsOrf = this.imageDataOrf.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsBarabara = this.imageDataBarbara.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsMystikum = this.imageDataMystikum.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsSeelenreise = this.imageDataSeelenreise.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsOffenestellen = this.imageDataOffenestellen.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsSilentelements = this.imageDataSilentelements.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsIrocode2011 = this.imageDataIrocode2011.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.itemsVd = this.imageDataVd.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));


    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);

    this.dataList = werbeData.employees;
    console.log(this.dataList);
  }

  close(): void {
    this.dialogRef.close();
  }
}



const dataVd = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/10.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/11.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/11.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/12.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/12.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/13.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/13.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/14.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/14.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/15.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/15.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/vd/16.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/vd/16.jpg'
  }
];

const dataIrocode2011 = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/10.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/11.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/11.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/irocode2011/12.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/irocode2011/12.png'
  }
];

const dataSilentelements = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/silentelements/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/silentelements/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/silentelements/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/silentelements/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/silentelements/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/silentelements/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/silentelements/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/silentelements/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/silentelements/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/silentelements/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/silentelements/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/silentelements/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/silentelements/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/silentelements/7.png'
  }];

const dataOffenestellen = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/10.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/11.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/11.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/12.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/12.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/offenestellen/13.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/offenestellen/13.jpg'
  }
];

const dataSeelenreise = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/seelenreise/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/seelenreise/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/seelenreise/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/seelenreise/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/seelenreise/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/seelenreise/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/seelenreise/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/seelenreise/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/seelenreise/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/seelenreise/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/seelenreise/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/seelenreise/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/seelenreise/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/seelenreise/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/seelenreise/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/seelenreise/8.png'
  }
];

const dataMystikum = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/mystikum/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/mystikum/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/mystikum/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/mystikum/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/mystikum/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/mystikum/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/mystikum/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/mystikum/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/mystikum/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/mystikum/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/mystikum/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/mystikum/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/mystikum/1.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/mystikum/1.jpg'
  }
];

const dataBarabara = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/barbara/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/barbara/10.png'
  },
];

const dataOrf = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/orf/0.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/orf/0.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/orf/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/orf/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/orf/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/orf/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/orf/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/orf/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/orf/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/orf/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/orf/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/orf/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/orf/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/orf/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/orf/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/orf/7.png'
  },

];

const dataTrattnerzone = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trattnerzone/10.png'
  },
];


const dataIon = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/ion/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/ion/10.png'
  },
];

const dataRock = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/rock/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/rock/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/rock/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/rock/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/rock/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/rock/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/rock/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/rock/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/rock/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/rock/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/rock/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/rock/6.png'
  },
];

const dataEristoff = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eristoff/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eristoff/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eristoff/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eristoff/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eristoff/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eristoff/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eristoff/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eristoff/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eristoff/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eristoff/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eristoff/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eristoff/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eristoff/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eristoff/7.png'
  },
];

const dataDj = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/djtour/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/djtour/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/djtour/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/djtour/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/djtour/3.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/djtour/3.jpg'
  },
];

const dataTowntown = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/towntown/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/towntown/10.png'
  },
];

const dataSchlank = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/schlankelinie/5.png'
  },
];

const dataMarkant = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/markant/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/markant/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/markant/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/markant/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/markant/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/markant/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/markant/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/markant/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/markant/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/markant/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/markant/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/markant/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/markant/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/markant/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/markant/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/markant/8.png'
  },
];

const dataTtc = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/trainingcompany/5.png'
  },
];

const dataAsfinag = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/asfinag/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/asfinag/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/asfinag/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/asfinag/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/asfinag/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/asfinag/3.png'
  }
];
const dataModemacher = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/9.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/9.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/10.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/10.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/11.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/11.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/12.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/12.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/13.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/13.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/modemacher/14.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/modemacher/14.jpg'
  }
];
const dataSchaerdinger = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/bergland/1.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/bergland/1.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/bergland/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/bergland/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/bergland/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/bergland/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/bergland/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/bergland/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/bergland/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/bergland/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/bergland/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/bergland/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/bergland/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/bergland/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/bergland/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/bergland/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/bergland/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/bergland/8.png'
  }
];

const dataHawle = [
  {
    srcUrl: 'assets/images/projekte/hawle_header_0.png',
    previewUrl: 'assets/images/projekte/hawle_header_0.png'
  },
  {
    srcUrl: 'assets/images/projekte/hawle_header_3.png',
    previewUrl: 'assets/images/projekte/hawle_header_3.png'
  },
  {
    srcUrl: 'assets/images/projekte/hawle_header.png',
    previewUrl: 'assets/images/projekte/hawle_header.png'
  },
  {
    srcUrl: 'assets/images/projekte/hawle_header_2.png',
    previewUrl: 'assets/images/projekte/hawle_header_2.png'
  }
];

const dataKochabo = [
  {
    srcUrl: 'assets/images/projekte/kochabo/1.png',
    previewUrl: 'assets/images/projekte/kochabo/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/2.png',
    previewUrl: 'assets/images/projekte/kochabo/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/3.png',
    previewUrl: 'assets/images/projekte/kochabo/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/4.png',
    previewUrl: 'assets/images/projekte/kochabo/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/5.png',
    previewUrl: 'assets/images/projekte/kochabo/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/6.png',
    previewUrl: 'assets/images/projekte/kochabo/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/7.png',
    previewUrl: 'assets/images/projekte/kochabo/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/8.png',
    previewUrl: 'assets/images/projekte/kochabo/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/9.png',
    previewUrl: 'assets/images/projekte/kochabo/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/10.png',
    previewUrl: 'assets/images/projekte/kochabo/10.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/11.png',
    previewUrl: 'assets/images/projekte/kochabo/11.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/12.png',
    previewUrl: 'assets/images/projekte/kochabo/12.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/13.png',
    previewUrl: 'assets/images/projekte/kochabo/13.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/14.png',
    previewUrl: 'assets/images/projekte/kochabo/14.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/15.png',
    previewUrl: 'assets/images/projekte/kochabo/15.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/16.png',
    previewUrl: 'assets/images/projekte/kochabo/16.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/17.png',
    previewUrl: 'assets/images/projekte/kochabo/17.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/18.png',
    previewUrl: 'assets/images/projekte/kochabo/18.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/19.png',
    previewUrl: 'assets/images/projekte/kochabo/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/19.png',
    previewUrl: 'assets/images/projekte/kochabo/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/20.png',
    previewUrl: 'assets/images/projekte/kochabo/20.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/21.png',
    previewUrl: 'assets/images/projekte/kochabo/21.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/22.png',
    previewUrl: 'assets/images/projekte/kochabo/22.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/23.png',
    previewUrl: 'assets/images/projekte/kochabo/23.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/24.png',
    previewUrl: 'assets/images/projekte/kochabo/24.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/25.png',
    previewUrl: 'assets/images/projekte/kochabo/25.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/26.png',
    previewUrl: 'assets/images/projekte/kochabo/26.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/27.png',
    previewUrl: 'assets/images/projekte/kochabo/27.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/28.png',
    previewUrl: 'assets/images/projekte/kochabo/28.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/29.png',
    previewUrl: 'assets/images/projekte/kochabo/29.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/30.png',
    previewUrl: 'assets/images/projekte/kochabo/30.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/31.png',
    previewUrl: 'assets/images/projekte/kochabo/31.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/32.png',
    previewUrl: 'assets/images/projekte/kochabo/32.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/33.png',
    previewUrl: 'assets/images/projekte/kochabo/33.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/34.png',
    previewUrl: 'assets/images/projekte/kochabo/34.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/35.png',
    previewUrl: 'assets/images/projekte/kochabo/35.png'
  },
  {
    srcUrl: 'assets/images/projekte/kochabo/36.png',
    previewUrl: 'assets/images/projekte/kochabo/36.png'
  }
];

const dataAdmiral = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/admiral_header.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/admiral_header.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/admiral1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/admiral1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/admiral2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/admiral2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/admiral3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/admiral3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/admiral4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/admiral4.png'
  }
];

const dataDrei2014 = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/10.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/11.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/11.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2014/12.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2014/12.png'
  }
];

const dataDrei2012 = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/10.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/11.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/11.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/drei2012/12.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/drei2012/12.jpg'
  }
];

const dataEva = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eva/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eva/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eva/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eva/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eva/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eva/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eva/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eva/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eva/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eva/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eva/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eva/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eva/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eva/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/eva/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/eva/8.png'
  }
];

const dataGustav3 = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav3/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav3/10.png'
  }
];

const dataGustav2 = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/7.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/8.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/8.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/9.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/9.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/gustav2/10.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/gustav2/10.png'
  }
];

const dataNufex = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/1.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/1.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/7.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/7.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/8.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/8.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/9.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/9.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/10.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/10.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/12.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/12.jpg'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/11.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischextern/11.jpg'
  }
];

const dataTracks = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/tracks/1.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/tracks/1.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/tracks/2.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/tracks/2.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/tracks/3.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/tracks/3.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/tracks/4.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/tracks/4.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/tracks/5.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/tracks/5.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/tracks/6.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/tracks/6.png'
  },
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/tracks/7.png',
    previewUrl: 'assets/images/projekte/referenzenbilder/tracks/7.png'
  }
];

const dataToniTipp = [
  {
    srcUrl: 'assets/images/projekte/referenzenbilder/nahundfrischintern/1.jpg',
    previewUrl: 'assets/images/projekte/referenzenbilder/nahundfrischintern/1.jpg'
  }
];



