import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IComments } from '../interfaces/icomments';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  private urlJsonServer = 'http://localhost:3000';

  constructor(private http: HttpClient) { }

  fetch() {
    return this.http.get(this.urlJsonServer + '/comments');
  }

  fetchSort() {
    return this.http.get(this.urlJsonServer + '/pl?_sort=id&_orplr=plsc');
  }

  add(Data: IComments, body: string) {
    return this.http.post(this.urlJsonServer + '/' + body, Data);
  }

  delete(id: number, body: string) {
    return this.http.delete(this.urlJsonServer + '/' + body + '/' + id);
  }

  update(payload: any, id: number, body: string) {
    return this.http.put(this.urlJsonServer + '/' + body + ' / ' + id, payload);
  }

}
