import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CommentService } from "../../services/comments.services";
import { tap } from 'rxjs/operators';
import { AddComments, UpdateComments, DeleteComments, GetComments } from '../actions/comment.action';

export class CommentStateModel {
    expressions: any;
}

@State<CommentStateModel>({
    name: 'appstateComments',
    defaults: {
        expressions: []
    }
})

@Injectable()
export class CommentState {
    constructor(private commentService: CommentService) { }

    @Selector()
    static selectStateData(state: CommentStateModel) {
        return state.expressions;
    }

    @Action(GetComments)
    getDataFromState(ctx: StateContext<CommentStateModel>) {
        return this.commentService.fetch().pipe(tap(returnData => {
            const state = ctx.getState();
            ctx.setState({
                ...state,
                expressions: returnData
            });
        }));
    }

    @Action(AddComments)
    addDeDataToState(ctx: StateContext<CommentStateModel>, { payload }: AddComments) {
        return this.commentService.add(payload, 'de').pipe(tap(returnData => {
            const state = ctx.getState();
            ctx.patchState({
                expressions: [...state.expressions, returnData]
            });
        }));
    }

    @Action(UpdateComments)
    updateDeDataOfState(ctx: StateContext<CommentStateModel>, { payload, id, i }: UpdateComments) {
        return this.commentService.update(payload, i, 'de').pipe(tap(returnData => {
            const state = ctx.getState();
            const expressionList = [...state.expressions];
            expressionList[i] = payload;
            ctx.setState({
                ...state,
                expressions: expressionList,
            });
        }));
    }

    @Action(DeleteComments)
    deleteDeDataFromState(ctx: StateContext<CommentStateModel>, { id }: DeleteComments) {
        return this.commentService.delete(id, 'de').pipe(tap(returnData => {
            const state = ctx.getState();
            const filteredArray = state.expressions.filter((contents: { id: number; }) => contents.id !== id);
            ctx.setState({
                ...state,
                expressions: filteredArray
            });
        }));
    }

}
