import { Injectable } from '@angular/core';
import { InConfiguration } from '../core/models/config.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public configData: InConfiguration;
  public sideMenuCollapsed: boolean;

  constructor(private router: Router) {
    this.setConfigData();
  }

  setConfigData() {
    this.sideMenuCollapsed = JSON.parse(localStorage.getItem('sideMenuCollapsed'));

    if (!this.sideMenuCollapsed) {
      // set start value
      localStorage.setItem('sideMenuCollapsed', JSON.stringify(false));
      this.router.navigate(['/dashboard/main']);
    }

    this.configData = {
      layout: {
        rtl: false, // options:  true & false
        variant: 'dark', // options:  light & dark
        theme_color: 'black', // options:  white, black, purple, blue, cyan, green, orange
        logo_bg_color: 'black', // options:  white, black, purple, blue, cyan, green, orange
        sidebar: {
          collapsed: this.sideMenuCollapsed, // options:  true & false
          backgroundColor: 'dark', // options:  light & dark
        },
      },
    };
  }

}
