import { IComments } from '../../interfaces/icomments';
// Hier definiert man jeweils vier Aktionen für CRUD-Operationen

// Read
export class GetComments {
    static readonly type = '[Comments] Fetch';
}

// sort
export class GetSortComments {
    static readonly type = '[CommentsSort] Fetch/Sort';
}

// Create
export class AddComments {
    static readonly type = '[Comments De] Add';
    constructor(public payload: IComments) { }
}

// Update
export class UpdateComments {
    static readonly type = '[Comments De] Update';
    constructor(public payload: IComments, public id: number, public i: number) { }
}

// Delete
export class DeleteComments {
    static readonly type = '[CommentsDe] Delete';
    constructor(public id: number) { }
}
