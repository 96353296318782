import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { UsersideService } from "src/app/services/usersides.services";
import { tap } from 'rxjs/operators';
import { AddUsersides, UpdateUsersides, DeleteUsersides, GetUsersides } from '../actions/userside.action';

export class UsersideStateModel {
    expressions: any;
}

@State<UsersideStateModel>({
    name: 'appstateUsersides',
    defaults: {
        expressions: []
    }
})

@Injectable()
export class UsersideState {
    constructor(private usersideService: UsersideService) { }

    @Selector()
    static selectStateData(state: UsersideStateModel) {
        return state.expressions;
    }

    @Action(GetUsersides)
    getDataFromState(ctx: StateContext<UsersideStateModel>) {
        return this.usersideService.fetch().pipe(tap(returnData => {
            const state = ctx.getState();
            ctx.setState({
                ...state,
                expressions: returnData
            });
        }));
    }

    @Action(AddUsersides)
    addDataToState(ctx: StateContext<UsersideStateModel>, { payload }: AddUsersides) {
        return this.usersideService.add(payload, 'de').pipe(tap(returnData => {
            const state = ctx.getState();
            ctx.patchState({
                expressions: [...state.expressions, returnData]
            });
        }));
    }

    @Action(UpdateUsersides)
    updateDataOfState(ctx: StateContext<UsersideStateModel>, { payload, id, i }: UpdateUsersides) {
        return this.usersideService.update(payload, i, 'de').pipe(tap(returnData => {
            const state = ctx.getState();
            const expressionList = [...state.expressions];
            expressionList[i] = payload;
            ctx.setState({
                ...state,
                expressions: expressionList,
            });
        }));
    }

    @Action(DeleteUsersides)
    deleteDataFromState(ctx: StateContext<UsersideStateModel>, { id }: DeleteUsersides) {
        return this.usersideService.delete(id, 'de').pipe(tap(returnData => {
            const state = ctx.getState();
            const filteredArray = state.expressions.filter((contents: { id: number; }) => contents.id !== id);
            ctx.setState({
                ...state,
                expressions: filteredArray
            });
        }));
    }

}
